import React, { useRef, useState } from 'react';
import Select, { StylesConfig, components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { colors } from '@/theme/theme';
import { useTranslation } from 'react-i18next';

import { Badge } from "@nextui-org/badge";

const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'transparent', width: '100%', borderRadius: 8 }),
    option: (styles, { isSelected }) => ({ ...styles, color: isSelected ? 'white' : 'black', borderRadius: 10, width: '95%', marginLeft: 10 }),
    input: (styles) => ({ ...styles, width: '100px', color: 'white', paddingLeft: 1 }),
    placeholder: (styles) => ({ ...styles, color: 'white' }),
    singleValue: (styles, { }) => ({ ...styles, color: 'white' }),
    multiValue: (styles) => ({ ...styles, borderRadius: 8 }),
    multiValueRemove: (styles) => ({ ...styles, borderRadius: 8, }),
    menuList: (styles) => ({ ...styles, borderRadius: 10 }),
    multiValueLabel: (styles) => ({ ...styles, borderRadius: 10 }),

};

export type MultiSelectedType = {
    label: string,
    value: string,
    slug: {
        key: string
        prefix: string
    }
}

interface Props {
    data: {
        value: string,
        label: string,
        slug: {
            key: string
            prefix: string
        }
    }[]
    title: string
    setData: React.Dispatch<React.SetStateAction<MultiSelectedType | undefined>>
}

const MultiValue = ({ index, getValue, ...props }) => {

    const maxToShow = 1

    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label)

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <Badge className='left-1' content={overflow.length} />
    ) : null
};

const MultiSelect: React.FC<Props> = ({ data, setData, title }) => {

    const { t } = useTranslation("glossary")
    const [selectedOption, setSelectedOption] = useState<MultiSelectedType>()
    const ref = useRef(null)

    // const selectedVals = selectedOption.map((x) => x?.value);
    // const hiddenOptions =
    //     selectedVals.length > 3 ? selectedVals.slice(0, 3) : [];
    // const options = data.filter(
    //     (x) => !hiddenOptions.includes(x.value)
    // );

    return (
        <div >
            <div className="ml-1 text-sm font-bold">{title}</div>
            <Select
                ref={ref}
                classNames={{
                    input: () => 'focus:outline-none focus:ring-0'
                }}
                styles={colourStyles}
                closeMenuOnSelect={false}
                components={{}}
                placeholder={t('choose')}
                isClearable
                onChange={setData}
                options={data}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: colors.primary,

                    },
                })}
            />
        </div>
    );
}

export default MultiSelect