import React, { useMemo, useState } from "react";
import heroimage from "../assets/HeroImg.png";
import heroimagewebp from "../assets/HeroImg.webp";
import calendaricon from "../assets/CalendarIcon.svg";
import groupimage from "../assets/Group.svg";
import searchbutton from "../assets/SearchButton.svg";
import { Link, usePage } from "@inertiajs/react";
import { useTranslation } from "react-i18next";
import { Datepicker } from "flowbite-react";
import TransLink from "@/utils/TransLink";
import WebPImage from "@/components/WebPImage";
import forestimagewebp from "@/assets/forest.webp";
import forestimage from "@/assets/forest.png";
import MultiSelect, { MultiSelectedType } from "./MultiSelect";

type FiltersType = {
    accomodationType: {
        data: {
            name: string
            slug: {
                key: string
                prefix: string
            }
        }[]
    },
    areaType: {
        data: {
            name: string
            slug: {
                key: string
                prefix: string
            }
        }[]
    }
}

export const formatDate = (date) => {
    if (!date) return "";
    return date
        .toLocaleDateString("en-GB", {
            // zh-Hans-CN y/m/d format
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .replace(/\//g, "-")
}

const HeroSection = ({ social }) => {

    const [startDate, setStartDate] = useState(formatDate(new Date()))
    const [endDate, setEndDate] = useState(formatDate(new Date()))
    const [areas, setAreas] = useState<MultiSelectedType | null>(null)
    const [accomodations, setAccomodations] = useState<MultiSelectedType | null>(null)
    const [visitorCount, setVisitorCount] = useState<number>(1)
    const [childrenCount, setChildrenCount] = useState<number>(0)

    const { t } = useTranslation(["common", "glossary"])

    const { filtersData }: { filtersData: FiltersType } = usePage().props

    const accomodationSelectData = useMemo(() => {
        return (
            filtersData.accomodationType.data.map((item) => (
                {
                    value: item.name,
                    label: item.name,
                    slug: item.slug
                }
            ))
        )
    }, [filtersData])

    const areaSelectData = useMemo(() => {
        return (
            filtersData.areaType.data.map((item) => (
                {
                    value: item.name,
                    label: item.name,
                    slug: item.slug
                }
            ))
        )
    }, [filtersData])

    const searchURL = useMemo(() => {

        let url = 'search?children=' + childrenCount + '&adults=' + visitorCount

        if (areas) {
            url += '&area[0]=' + areas?.slug.key
        }

        if (accomodations) {
            url += '&accomodation_types[0]=' + accomodations?.slug.key
        }

        return url

    }, [areas, accomodations, visitorCount, childrenCount])


    const handleIncrement = (type) =>
        type === "children"
            ? setChildrenCount(childrenCount + 1)
            : setVisitorCount(visitorCount + 1);

    const handleDecrement = (type) => {
        if (type === "children" && childrenCount > 0) {
            setChildrenCount(childrenCount - 1);
        } else if (type === "visitors" && visitorCount > 1) {
            setVisitorCount(visitorCount - 1);
        }
    };


    return (
        <section>
            <div className="relative z-40 bg-black">
                <WebPImage
                    className="w-full md:h-[800px] h-[750px] opacity-60 object-cover"
                    srcWebP={heroimagewebp}
                    srcFallback={heroimage}
                    alt="Hero"
                />
                <div className="absolute inset-0 flex items-center justify-between lg:mt-32 ">
                    <div className="xl:ml-40 3xl:ml-[15%] xl:w-1/2 xl:h-4/6 w-full text-white flex flex-col justify-between">
                        <div>
                            <p className="md:text-2xl text-xl text-center  md:text-center xl:text-left font-extralight">
                                {t("ultimate_destination", { ns: "glossary" })}
                            </p>
                            <h1 className="md:text-8xl mb-8 text-6xl  text-center md:text-center xl:text-left font-bold mt-2">
                                {t("thassos", { ns: "glossary" })}
                            </h1>
                            <p className="md:text-5xl text-2xl md:mb-12 xl:mb-0 mb-14 text-center md:text-center xl:text-left font-bold mt-4">
                                {t("live_try_discover")}
                            </p>
                        </div>
                        <div className="md:mt-auto m-auto md:mx-4 xl:m-0 xl:w-[1050px] 3xl:w-[70vw] max-md:w-[350px]">
                            <div className="ml-4 mb-2 md:mb-0 text-center md:text-left  text-2xl font-bold">
                                {t("where_to_stay", { ns: "glossary" })}
                            </div>
                            <div className="bg-white bg-opacity-40 backdrop-blur-lg p-5 rounded-xl w-full">
                                <div className="flex flex-col lg:flex-row items-center justify-between gap-8 ">
                                    {" "}


                                    <div className="flex flex-col w-full">
                                        <MultiSelect title={t('area', { ns: 'glossary' })} setData={setAreas} data={areaSelectData} />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <MultiSelect title={t('accomodation_type', { ns: 'glossary' })} setData={setAccomodations} data={accomodationSelectData} />
                                    </div>

                                    <div className="flex flex-col items-center md:items-start">
                                        <div className="flex items-center space-x-6">
                                            <div className="rounded w-12 h-12 mr-2">
                                                <img
                                                    src={groupimage}
                                                    alt="user"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="text-sm font-medium text-white">
                                                    {t("guests", {
                                                        ns: "glossary",
                                                    })}
                                                </label>
                                                <div className="flex items-center mt-1 space-x-4">
                                                    <button
                                                        title="-"
                                                        className="border-2 border-white text-white w-6 h-6 rounded-full flex items-center justify-center"
                                                        onClick={() =>
                                                            handleDecrement(
                                                                "visitors",
                                                            )
                                                        }
                                                        aria-label="Decrease visitor count"
                                                    >
                                                        -
                                                    </button>
                                                    <p className="text-white">
                                                        {visitorCount}
                                                    </p>
                                                    <button
                                                        title="+"
                                                        className="border-2 border-white text-white w-6 h-6 rounded-full flex items-center justify-center"
                                                        onClick={() =>
                                                            handleIncrement(
                                                                "visitors",
                                                            )
                                                        }
                                                        aria-label="Increase visitor count"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col  ml-0 items-center md:items-start">
                                        <div className="flex items-center space-x-6">
                                            <div className="rounded w-12 h-12 mr-2">
                                                <img
                                                    src={groupimage}
                                                    alt="user"
                                                />
                                            </div>
                                            <div className="flex flex-col mr-8">
                                                <label className="text-sm font-medium text-white">
                                                    {t("childs", {
                                                        ns: "glossary",
                                                    })}
                                                </label>
                                                <div className="flex items-center mt-1 space-x-4 z-10">
                                                    <button
                                                        title="-"
                                                        className="border-2 border-white text-white w-6 h-6 rounded-full flex items-center justify-center"
                                                        onClick={() =>
                                                            handleDecrement(
                                                                "children",
                                                            )
                                                        }
                                                        aria-label="Decrease visitor count"
                                                    >
                                                        -
                                                    </button>
                                                    <p className="text-white">
                                                        {childrenCount}
                                                    </p>
                                                    <button
                                                        title="+"
                                                        className="border-2 border-white text-white w-6 h-6 rounded-full flex items-center justify-center"
                                                        onClick={() =>
                                                            handleIncrement(
                                                                "children",
                                                            )
                                                        }
                                                        aria-label="Increase visitor count"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hidden md:block">
                                        <div className="flex flex-col items-center md:items-end">
                                            <TransLink
                                                className="rounded w-12 h-12 hover:opacity-80  hover:shadow-lg flex items-center justify-center transition active:scale-95"
                                                url={searchURL}
                                                aria-label="Search"
                                            >
                                                <img
                                                    src={searchbutton}
                                                    alt="search"
                                                />
                                            </TransLink>
                                        </div>
                                    </div>

                                    <div className="block md:hidden">
                                        <div className="flex flex-col items-center md:items-end">
                                            <TransLink
                                                className="rounded w-[170px] md:ml-4 ml-0 h-10 hover:opacity-80 hover:shadow-lg flex items-center justify-between pl-4 bg-primary text-white transition active:scale-95"
                                                url={searchURL}
                                                aria-label={t("search", { ns: "glossary" })}
                                            >
                                                <span className="ml-2">
                                                    {t("search", { ns: "glossary" })}
                                                </span>
                                                <img
                                                    src={searchbutton}
                                                    alt={t("search", { ns: "glossary" })}
                                                    className="rounded mr-2 h-10"
                                                />
                                            </TransLink>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center space-x-6 mt-8 2xl:hidden">
                            {social?.map((item, index) => {
                                if (!item.icon && !item.image) {
                                    return null;
                                }
                                return (
                                    <a
                                        key={index}
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-white"
                                    >
                                        {item.icon ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.icon,
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={item.image}
                                                loading="lazy"
                                                alt={item.name}
                                                className="h-5"
                                            />
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    <div className="hidden 2xl:block">
                        <div className="absolute bottom-0 mr-20 right-4 flex flex-col items-center space-y-4">
                            {social?.map((item, index) => {
                                if (!item.icon && !item.image) {
                                    return null;
                                }
                                return (
                                    <a
                                        key={index}
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="w-6 h-6 text-white hover:scale-125 transition duration-150"
                                    >
                                        {item.icon ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.icon,
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={item.image}
                                                loading="lazy"
                                                alt={item.name}
                                            />
                                        )}
                                    </a>
                                );
                            })}
                            <div className="w-px h-40 bg-white mt-4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
